import { Actions, pageViewObservable } from '@rategravity/frontend/modules/user-actions';
import {
  OwnUpComponentLibraryProvider,
  ownUpLightTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { SplitFactory } from '@splitsoftware/splitio-react';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';
import Rx from 'rxjs/Rx';
import { Page } from '../components/page';
import { ActionPipe } from '../hooks/use-action-pipe';
import { firstPartyTrackingObserver } from '../modules/1pt-observer';
import { trackingInstance } from '../modules/1pt-tracking-instance';
import { googleObserver } from '../modules/conversions/google/google-observer';
import { history } from '../redux/router';
import { GeneratingOverlay } from './components/generating-overlay/wrapper';
import { PreapprovalHeader } from './components/header';
import { ErrorPage } from './pages/error';
import { Expired } from './pages/expired';
import { GeneratePage } from './pages/generate';
import { LoadingPage } from './pages/loading';
import { SuccessPage } from './pages/success';
const splitUrl = process.env.SPLIT_URL || 'https://staging.split.ownup.com';

const actionPipe = new Rx.ReplaySubject<Actions>(10);
// Route page view actions to the action pipe
pageViewObservable(history).subscribe(actionPipe);

actionPipe.subscribe(firstPartyTrackingObserver());
actionPipe.subscribe(googleObserver());

export const PreapprovalAppWrapper = () => {
  const splitConfig = useMemo(
    () => ({
      core: {
        authorizationKey: process.env.SPLIT_API_KEY || 'qdjduua8u5orl12gguii27vam9g73rccr5lf',
        key: trackingInstance.getAnonymousId() || '',
        trafficType: 'Anonymous'
      },
      urls: {
        sdk: `${splitUrl}/sdk-api`,
        events: `${splitUrl}/events-api`,
        auth: `${splitUrl}/auth-api`
      }
    }),
    []
  );
  return (
    <SplitFactory config={splitConfig}>
      <OwnUpComponentLibraryProvider>
        <PreapprovalsApp />
      </OwnUpComponentLibraryProvider>
    </SplitFactory>
  );
};

export interface PreapprovalsAppProps {
  useDefaultHeader?: boolean;
  rootPath?: string;
  bodyPrefix?: React.ReactNode;
}

export const PreapprovalsApp = ({
  useDefaultHeader = true,
  rootPath = '/preapproval',
  bodyPrefix = null
}: PreapprovalsAppProps) => {
  return (
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <Helmet title="Your Own Up Preapproval" />
      <ActionPipe value={actionPipe}>
        <Switch>
          <Route path={`${rootPath}/error`} component={ErrorPage} />
          <Route
            path={`${rootPath}/:constraintsId`}
            render={() => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <LoadingPage>
                <Page header={useDefaultHeader ? <PreapprovalHeader /> : undefined}>
                  {bodyPrefix}
                  <div>
                    <Expired>
                      <GeneratingOverlay>
                        <Route exact path={`${rootPath}/:constraintsId`} component={GeneratePage} />
                        <Route
                          path={`${rootPath}/:constraintsId/:preapprovalId`}
                          component={SuccessPage}
                        />
                      </GeneratingOverlay>
                    </Expired>
                  </div>
                </Page>
              </LoadingPage>
            )}
          />
        </Switch>
      </ActionPipe>
    </OwnUpThemeProvider>
  );
};
