import createSagaMiddleware from '@redux-saga/core';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { withDefault } from 'redux-compose';
import { all, fork } from 'redux-saga/effects';
import { apiSaga } from './api';
import { history, reducer } from './reducer';
import { sagaWithoutApi } from './saga';
import { createMiddleware } from './tracking';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaWithApi = function* () {
  yield all([fork(sagaWithoutApi), fork(apiSaga)]);
};

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  withDefault({}, reducer),
  composeEnhancers(
    applyMiddleware(sagaMiddleware, routerMiddleware(history), createMiddleware(history))
  )
);

sagaMiddleware.run(sagaWithApi);
