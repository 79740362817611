import {
  OwnUpFillButtonPrimary,
  OwnUpFillButtonSecondary
} from '@rategravity/own-up-component-library';
import { DownloadIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/download';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { SharePreapproval } from '../../components/share/share-inputs';
import { TextSkeleton } from '../../components/text-skeleton';
import { reset } from '../../redux/data';
import { successPageSelector } from '../../redux/selectors';
import { track } from '../../redux/tracking';

export const SuccessPage = () => {
  const dispatch = useDispatch();
  const downloadLink = useSelector(successPageSelector);
  const { accountId } = useSelector(manifestAccountInfoSelector);
  const onResetClick = () => dispatch(reset());
  const onDownloadClick = () => dispatch(track('Clicked Download PA', {}));
  const DownloadButton = (
    <a
      href={downloadLink}
      download
      target="_new"
      onClick={() => {
        onDownloadClick();
        return true;
      }}
    >
      <OwnUpFillButtonPrimary icon={DownloadIcon} iconPosition="left">
        Download letter
      </OwnUpFillButtonPrimary>
    </a>
  );
  const ResetButton = (
    <OwnUpFillButtonSecondary onClick={onResetClick}>Create a new letter</OwnUpFillButtonSecondary>
  );

  return (
    <TextSkeleton
      title="Success!"
      body="We’ve sent your preapproval letter by email. You may also download it below."
      ctas={[DownloadButton, ResetButton]}
      additionalContent={<SharePreapproval />}
      fullHeight={false}
      accountId={accountId}
    />
  );
};
